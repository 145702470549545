import React from 'react'
import ApplicationRoutes from './Routes'
import 'react-datepicker/dist/react-datepicker.css'

import './styles/FontImport.css'
import GlobalStyles from './styles/GlobalStyles'

function App() {
  return (
    <>
      <GlobalStyles />
      <ApplicationRoutes />
    </>
  )
}

export default App

import React, { useState } from 'react'

import {
  Container,
  Header,
  HeaderWrapper,
  LogoContainer,
  MenuContainer,
  MenuItem,
  DownloadBanner,
  ImageContainer,
  DownloadContent,
  DownloadTitle,
  DownloadText,
  DownloadButtons,
  DownloadButton,
  MenuToggle
} from './styles'

import { HiMenu, HiX } from 'react-icons/hi'

import LogoHeader from '../../assets/images/Logo_header.svg'
import BannerDownload from '../../assets/images/Banner_download.svg'
import Iphone from '../../assets/images/Mockup_iphone.png'
import GooglePlay from '../../assets/images/Google_play.svg'
import AppStore from '../../assets/images/App_store.svg'

const Download: React.FC = () => {
  const [menuOpened, setMenuOpened] = useState(false)

  return (
    <Container>
      <Header>
        <HeaderWrapper>
          <LogoContainer href="/">
            <img src={LogoHeader} alt="Spot Player" />
          </LogoContainer>
          <MenuToggle onClick={() => setMenuOpened(!menuOpened)}>
            {!menuOpened ? <HiMenu size={24} /> : <HiX size={24} />}
          </MenuToggle>
          <MenuContainer opened={menuOpened}>
            <MenuContainer opened={menuOpened}>
              <MenuItem href="/download">Download</MenuItem>
            </MenuContainer>
          </MenuContainer>
        </HeaderWrapper>
      </Header>

      <DownloadBanner image={BannerDownload} id="download">
        <ImageContainer>
          <img src={Iphone} alt="SpotPlayer grave seu spot" />
        </ImageContainer>
        <DownloadContent>
          <DownloadTitle>Download</DownloadTitle>
          <DownloadText>
            Baixe agora o Pediatrix e divirta-se aprendendo sobre pediatria!
            <br />
            <br />O Pediatrix é um aplicativo essencial para pais e
            acompanhantes que desejam estar informados sobre a saúde de seus
            filhos.
          </DownloadText>
          <DownloadButtons>
            <DownloadButton href="https://apps.apple.com/br/app/pediatrix/id1661785420">
              <img src={AppStore} alt="Disponível na App Store" />
            </DownloadButton>
            <DownloadButton href="https://play.google.com/store/apps/details?id=br.com.specterlabs.pediatrix">
              <img src={GooglePlay} alt="Disponível no Google Play" />
            </DownloadButton>
          </DownloadButtons>
        </DownloadContent>
      </DownloadBanner>
    </Container>
  )
}

export default Download

import React from 'react'

import { Switch, Route } from 'react-router-dom'

import Institucional from './components/Institucional'
import Download from './components/Download'

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Institucional} />
      <Route exact path="/download" component={Download} />
    </Switch>
  )
}

export default Routes

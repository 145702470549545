import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 70px;
  overflow-x: hidden;
`

export const Header = styled.div`
  display: flex;
  height: 70px;
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 1000;

  -webkit-box-shadow: 0px 5px 15px 0px rgba(138, 149, 158, 0.15);
  -moz-box-shadow: 0px 5px 15px 0px rgba(138, 149, 158, 0.15);
  box-shadow: 0px 5px 15px 0px rgba(138, 149, 158, 0.15);
`

export const HeaderWrapper = styled.div`
  display: flex;
  padding: 0 70px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const LogoContainer = styled.a`
  display: flex;
  height: 100%;
  align-items: center;

  > img {
    height: 36px;
    object-fit: cover;
  }

  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`

export const MenuToggle = styled.button`
  display: none;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 5px;
  right: 5px;
  justify-content: center;
  align-items: center;
  color: var(--medium-gray);

  :focus {
    outline: none;
  }

  @media (max-width: 960px) {
    display: flex;
  }
`

interface MenuContainerProps {
  opened: boolean
}

export const MenuContainer = styled.div<MenuContainerProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 960px) {
    position: absolute;
    left: 0;
    top: 70px;
    height: calc(100vh - 70px);
    width: 100%;
    background: white;
    justify-content: center;
    transition: 0.5s ease;

    -webkit-box-shadow: inset 0px 5px 15px -15px rgba(138, 149, 158, 1);
    -moz-box-shadow: inset 0px 5px 15px -15px rgba(138, 149, 158, 1);
    box-shadow: inset 0px 5px 15px -15px rgba(138, 149, 158, 1);
    flex-direction: column;

    transform: translateY(${props => (!props.opened ? '200vh' : 0)});

    & > a {
      margin: 28px 0;
      font-size: 1.6em;
    }
  }
`

export const MenuItem = styled.a`
  text-decoration: none;
  font-size: 15px;
  color: var(--dark-gray);
  cursor: pointer;
  margin-left: 40px;
  transition: 0.2s ease;

  &:hover {
    color: var(--primary);
  }
`

interface BannerProps {
  image: any
}

export const Banner = styled.div<BannerProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  background-image: ${props => 'url(' + props.image + ')'};
  background-size: cover;
  background-position: center;
  height: calc(100vh - 70px);

  @media (max-width: 960px) {
    height: inherit;
    padding: 60px 0;
  }
`

export const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  padding: 0 40px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`

interface BannerData {
  right?: boolean
}

export const BannerContent = styled.div<BannerData>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.right ? 'center' : 'flex-start')};
  flex: 2;
`

export const Title = styled.div`
  font-size: 2.5em;
  color: white;
  text-align: left;

  @media (max-width: 960px) {
    text-align: center;
    width: 100%;
  }
`

export const ImageBanner = styled.img`
  max-width: 100%;

  @media (max-width: 960px) {
    max-width: 75%;
    margin-top: 30px;
  }
`

export const Subtitle = styled.div`
  color: white;
  max-width: 75%;
  margin: 28px 0;
  text-align: left;

  @media (max-width: 960px) {
    text-align: center;
    max-width: inherit;
  }
`

export const Cta = styled.a`
  text-decoration: none;
  display: flex;
  cursor: pointer;
  transition: 0.2s ease;
  padding: 12px 24px;
  font-weight: 600;
  color: var(--primary);
  background: white;
  border-radius: 5px;

  &:hover {
    background: var(--black);
    transform: scale(0.9);
    color: white;
  }

  @media (max-width: 960px) {
    margin: auto;
  }
`

export const Features = styled.div`
  display: flex;
  width: 100vw;
  height: calc(100vh - 70px);
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 960px) {
    height: inherit;
  }
`

export const FeaturesWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
`

export const FeaturesTitle = styled.div`
  font-size: 1.5em;
  color: var(--primary);
  text-align: center;
  font-weight: bold;
`

export const Divisor = styled.div`
  width: 80px;
  height: 3px;
  border-radius: 3px;
  background: var(--primary);
  margin: 28px auto;
`

export const FeaturesContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 28px 0;

  @media (max-width: 960px) {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
    gap: 16px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  padding: 28px;
  background-color: white;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(138, 149, 158, 0.12);
  -moz-box-shadow: 0px 5px 15px 0px rgba(138, 149, 158, 0.12);
  box-shadow: 0px 5px 15px 0px rgba(138, 149, 158, 0.12);
  margin: 0 15px;
  border-radius: 5px;
  transition: 0.2s ease;
  cursor: pointer;

  :hover {
    transform: translateY(-10px);
  }

  @media (max-width: 960px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 0;
    margin-bottom: 24px;
  }
`

interface FeatureIconData {
  bg: string
}

export const FeatureIconContainer = styled.div<FeatureIconData>`
  background-image: ${props => 'url(' + props.bg + ')'};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FeatureIcon = styled.img`
  width: 38px;
  height: 38px;
  color: var(--primary);
`

export const FeatureText = styled.div`
  margin: 24px 0 8px 0;
  font-weight: 700;
  font-size: 1.2em;
  text-align: center;
  color: var(--dark-gray);
`

export const FeatureDesc = styled.div`
  text-align: center;
  font-size: 0.8em;
  text-align: center;
  color: var(--dark-gray);
`

export const DownloadBanner = styled.div<BannerProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: calc(100vh - 70px);
  padding: 0 calc((100% - 1000px) / 2);

  background-image: ${props => 'url(' + props.image + ')'};
  background-size: cover;
  background-position: center;

  @media (max-width: 960px) {
    height: inherit;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
  }
`

export const ImageContainer = styled.div`
  height: 125%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  > img {
    margin-bottom: 30%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 960px) {
    height: inherit;
    justify-content: center;
    margin-top: -30%;
    > img {
      width: 80%;
      height: inherit;
      margin-bottom: 0;
    }
  }
`

export const ImageContainerLoja = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-end;
  > img {
    object-fit: cover;
    height: 500px;
  }

  @media (max-width: 960px) {
    display: none;
  }
`

export const DownloadContent = styled.div`
  color: #3e4853;
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  flex: 1;

  @media (max-width: 960px) {
    width: 75%;
    align-items: center;
    text-align: center;
    margin: 0;
    margin-top: 18px;
  }
`

export const DownloadTitle = styled.h1`
  font-size: 2em;
  font-weight: 700;
  color: #3e4853;
`

export const DownloadText = styled.h2`
  font-size: 0.9em;
  font-weight: 500;
  margin: 28px 0;
`

export const DownloadButtons = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 960px) {
    justify-content: center;
    align-items: center;
  }
`

export const DownloadButton = styled.a`
  > img {
    width: 180px;
    margin-right: 10px;
  }
  @media (max-width: 960px) {
    flex: 1;
    padding: 0 5px;

    > img {
      width: 100%;
    }
  }
`

export const BannerSuaLoja = styled.div<BannerProps>`
  height: calc(100vh - 70px);
  background-color: var(--black);
  background-image: ${props => 'url(' + props.image + ')'};
  background-size: cover;
  background-position: center;

  @media (max-width: 960px) {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const SuaLojaWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0 calc((100% - 1000px) / 2);
`

export const SuaLojaContent = styled.div`
  display: flex;
  width: 60%;
  max-width: 1000px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;

  @media (max-width: 960px) {
    width: 75%;
    align-items: center;
    text-align: center;
  }
`

export const SuaLojaSuperTitle = styled.h1`
  color: white;
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 42px;
  text-align: center;
`

export const SuaLojaTitle = styled.h1`
  color: white;
  font-size: 2.4em;
  font-weight: 500;
  margin-bottom: 18px;
  text-align: center;
`

export const SuaLojaText = styled.h2`
  font-size: 1em;
  font-weight: 400;
  color: white;
  text-align: center;
  text-shadow: 0px 2px 8px #0000006b;
`
export const SuaLojaCta = styled.a`
  text-decoration: none;
  display: flex;
  cursor: pointer;
  transition: 0.2s ease;
  padding: 12px 24px;
  font-weight: 600;
  color: var(--primary);
  background: white;
  border-radius: 5px;
  margin-top: 42px;

  &:hover {
    background: var(--black);
    transform: scale(0.9);
    color: white;
  }
`

export const Footer = styled.div`
  display: flex;
  background: #191919;
  width: 100%;
  justify-content: center;
`

export const FooterWrapper = styled.div`
  max-width: 1000px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 0;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }
`

export const Col1 = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    width: 50%;
  }
`

export const LogoFooter = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  > img {
    max-width: 80%;
  }

  @media (max-width: 960px) {
    > img {
      max-width: 100%;
    }
  }
`

export const Col2 = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    width: 80%;
    margin: 32px 0;
    flex-direction: column;
    > a {
      margin: 8px 0;
    }
  }
`

export const FooterItem = styled.a`
  text-decoration: none;
  color: white;
  margin: 0 10px;
  transition: 0.2s ease;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;

  &:hover {
    color: var(--primary);
  }
`

export const Col3 = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  @media (max-width: 960px) {
    width: 80%;
    > a {
      margin: 0 8px;
    }
  }
`
export const FooterButton = styled.a`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: white;
  margin-left: 15px;
  display: flex;
  color: #191919;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;

  :hover {
    color: var(--primary);
  }
`

export const IconContainer = styled.div`
  color: white;
`

import React, { useState } from 'react'

import {
  Container,
  Header,
  HeaderWrapper,
  LogoContainer,
  MenuContainer,
  MenuItem,
  Banner,
  BannerWrapper,
  Title,
  Subtitle,
  Cta,
  Features,
  FeaturesWrapper,
  FeaturesTitle,
  Divisor,
  FeaturesContent,
  FeatureCard,
  FeatureIconContainer,
  FeatureIcon,
  FeatureText,
  FeatureDesc,
  DownloadBanner,
  ImageContainer,
  DownloadContent,
  DownloadTitle,
  DownloadText,
  DownloadButtons,
  DownloadButton,
  BannerSuaLoja,
  SuaLojaWrapper,
  SuaLojaContent,
  SuaLojaTitle,
  SuaLojaSuperTitle,
  SuaLojaText,
  Footer,
  FooterWrapper,
  Col1,
  LogoFooter,
  Col2,
  FooterItem,
  Col3,
  FooterButton,
  MenuToggle,
  BannerContent,
  ImageBanner,
  IconContainer,
  SuaLojaCta
} from './styles'

import { FaGooglePlay, FaApple } from 'react-icons/fa'
import { HiMenu, HiX, HiDeviceMobile } from 'react-icons/hi'
import { AiOutlineLock } from 'react-icons/ai'

import LogoHeader from '../../assets/images/Logo_header.svg'
import LogoFooterImg from '../../assets/images/Logo_footer.svg'
import BannerPrincipal from '../../assets/images/Banner_principal.svg'
import BannerDownload from '../../assets/images/Banner_download.svg'
import BannerLoja from '../../assets/images/Banner_loja.png'
import BannerTampa from '../../assets/images/Tampa.png'
import Iphone from '../../assets/images/Mockup_iphone.png'
import GooglePlay from '../../assets/images/Google_play.svg'
import AppStore from '../../assets/images/App_store.svg'
import Shape1 from '../../assets/images/shape_1.png'
import Shape2 from '../../assets/images/shape_2.png'
import Shape3 from '../../assets/images/shape_3.png'
import Shape4 from '../../assets/images/shape_4.png'
import Icon1 from '../../assets/images/icon-1.svg'
import Icon2 from '../../assets/images/icon-2.svg'
import Icon3 from '../../assets/images/icon-3.svg'
import Icon4 from '../../assets/images/icon-4.svg'

const Institucional: React.FC = () => {
  const [menuOpened, setMenuOpened] = useState(false)

  const features = [
    {
      icon: Icon1,
      bg: Shape1,
      text: 'Questões variadas sobre pediatria',
      desc: 'ste seus conhecimentos e aprenda mais sobre a saúde do seu filho.'
    },
    {
      icon: Icon2,
      bg: Shape2,
      text: 'Interface interativa e intuitiva',
      desc: 'Aproveite a experiência de jogo fácil e divertida.'
    },
    {
      icon: Icon3,
      bg: Shape3,
      text: 'Feedback imediato',
      desc: 'Saiba na hora se acertou ou errou a questão e veja a resposta correta.'
    },
    {
      icon: Icon4,
      bg: Shape4,
      text: 'Disponível offline',
      desc: 'Jogue em qualquer lugar, a qualquer hora, sem precisar de internet.'
    }
  ]

  return (
    <Container>
      <Header>
        <HeaderWrapper>
          <LogoContainer href="/">
            <img src={LogoHeader} alt="Pediatrix logo" />
          </LogoContainer>
          <MenuToggle onClick={() => setMenuOpened(!menuOpened)}>
            {!menuOpened ? <HiMenu size={24} /> : <HiX size={24} />}
          </MenuToggle>
          <MenuContainer opened={menuOpened}>
            <MenuItem href="/download">Download</MenuItem>
          </MenuContainer>
        </HeaderWrapper>
      </Header>
      <Banner image={BannerPrincipal}>
        <BannerWrapper>
          <BannerContent style={{ flex: 2 }}>
            <Title>
              CONHEÇA O
              <br />
              <b>PEDIATRIX!</b>
            </Title>
            <Subtitle>
              Divirta-se enquanto aprende sobre pediatria! O Pediatrix é um quiz
              especialmente desenvolvido para pais e acompanhantes de pacientes.
            </Subtitle>
            <Cta href="/download">Fazer download</Cta>
          </BannerContent>
          <BannerContent right>
            <ImageBanner src={BannerTampa} />
          </BannerContent>
        </BannerWrapper>
      </Banner>
      <Features>
        <FeaturesWrapper>
          <FeaturesTitle>
            O QUIZ DE PEDIATRIA PARA PAIS E ACOMPANHANTES
          </FeaturesTitle>
          <Divisor />
          <FeaturesContent>
            {features.map((feature, i) => (
              <FeatureCard key={i}>
                <FeatureIconContainer bg={feature.bg}>
                  <FeatureIcon src={feature.icon} />
                </FeatureIconContainer>
                <FeatureText>{feature.text}</FeatureText>
                <FeatureDesc>{feature.desc}</FeatureDesc>
              </FeatureCard>
            ))}
          </FeaturesContent>
        </FeaturesWrapper>
      </Features>
      <BannerSuaLoja image={BannerLoja}>
        <SuaLojaWrapper>
          <SuaLojaContent>
            <IconContainer>
              <HiDeviceMobile size={38} />
            </IconContainer>
            <SuaLojaSuperTitle>
              QUIZ DE PEDIATRIA NA PALMA DA SUA MÃO
            </SuaLojaSuperTitle>
            <SuaLojaTitle>
              TESTE SEUS CONHECIMENTOS ENQUANTO
              <br /> <b>SE DIVERTE!</b>
            </SuaLojaTitle>
            <SuaLojaText>
              Com o Pediatrix, você pode testar seus conhecimentos sobre
              pediatria enquanto se diverte. São diversas perguntas e respostas
              para você aprender sobre a saúde do seu filho de uma forma
              descontraída.
              <br />
              Além disso, o aplicativo conta com uma interface fácil e
              interativa, feedback imediato e pode ser jogado offline. Baixe
              agora!
            </SuaLojaText>
            <SuaLojaCta href="/download">Fazer download</SuaLojaCta>
          </SuaLojaContent>
        </SuaLojaWrapper>
      </BannerSuaLoja>
      <DownloadBanner image={BannerDownload} id="download">
        <ImageContainer>
          <img src={Iphone} alt="SpotPlayer grave seu spot" />
        </ImageContainer>
        <DownloadContent>
          <DownloadTitle>Download</DownloadTitle>
          <DownloadText>
            Baixe agora o Pediatrix e divirta-se aprendendo sobre pediatria!
            <br />
            <br />O Pediatrix é um aplicativo essencial para pais e
            acompanhantes que desejam estar informados sobre a saúde de seus
            filhos.
          </DownloadText>
          <DownloadButtons>
            <DownloadButton href="https://apps.apple.com/br/app/pediatrix/id1661785420">
              <img src={AppStore} alt="Disponível na App Store" />
            </DownloadButton>
            <DownloadButton href="https://play.google.com/store/apps/details?id=br.com.specterlabs.pediatrix">
              <img src={GooglePlay} alt="Disponível no Google Play" />
            </DownloadButton>
          </DownloadButtons>
        </DownloadContent>
      </DownloadBanner>
      <Footer>
        <FooterWrapper>
          <Col1>
            <LogoFooter>
              <img src={LogoFooterImg} alt="SpotPlayer" />
            </LogoFooter>
          </Col1>
          <Col2>
            <FooterItem href="http://specterlabs.com.br/privacy-policies/pediatrix/Terms_of_use.html">
              Termos de uso
            </FooterItem>
            <FooterItem href="http://specterlabs.com.br/privacy-policies/pediatrix/">
              Políticas de privacidade
            </FooterItem>
          </Col2>
          <Col3>
            <FooterButton href="https://play.google.com/store/apps/details?id=br.com.specterlabs.pediatrix">
              <FaGooglePlay size={14} />
            </FooterButton>
            <FooterButton href="https://apps.apple.com/br/app/pediatrix/id1627263657">
              <FaApple size={18} />
            </FooterButton>
            <FooterButton href="https://admin.pediatrix.app">
              <AiOutlineLock size={18} />
            </FooterButton>
          </Col3>
        </FooterWrapper>
      </Footer>
    </Container>
  )
}

export default Institucional
